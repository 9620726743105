import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { IdecoratedHeadlineBlock } from './decoratedHeadline.model';
import { TailwindService } from '../../../../service';
import { TextColorOption } from '@seven1/model';

@Component({
    selector: 'app-decorated-headline',
    imports: [NgClass, NgTemplateOutlet],
    templateUrl: './decoratedHeadline.component.html',
    styleUrl: './decoratedHeadline.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'hostClasses()',
    }
})
export class DecoratedHeadlineComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<IdecoratedHeadlineBlock>();

    headingClasses = computed<string[]>(() => {
        const block = this.block();
        return [
            'break-words',
            ...this._tailwind.getSizeClasses(block),
            ...this._tailwind.getTypographyClasses(block),
        ];
    });

    hostClasses = computed<string[]>(() => {
        const block = this.block();
        return [
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            'block'
        ];
    });

    accentTextClasses = computed<string[]>(() => {
        const blok = this.block();
        const block = { // todo: cleanup here
            display: blok.displayAccent,
            displayTablet: blok.displayAccentTablet,
            displayDesktop: blok.displayAccentDesktop,
            color: blok.accentColor as TextColorOption
        };
        return [
            'break-words',
            'uppercase',
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getTypographyClasses(block),
        ];
    });

}
