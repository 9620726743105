import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, ViewEncapsulation } from '@angular/core';
import { ILinkBlock } from './link.model';
import { TailwindService } from '../../../../service';
import { DomSanitizer } from '@angular/platform-browser';
import { DesanitizeSlashPipe } from '@seven1/angular/utils';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { CachedUrlResolverPipe, SbLinkHelper } from '@seven1/angular-storyblok/link';
import { DOCUMENT, NgClass } from '@angular/common';
import { PlatformService } from '@seven1/angular/ssr';

@Component({
    selector: 'app-link',
    imports: [DesanitizeSlashPipe, RouterLink, CachedUrlResolverPipe, NgClass, RouterLinkActive],
    templateUrl: './link.component.html',
    styleUrl: './link.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent implements OnInit {
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);
    private _route = inject(ActivatedRoute);
    private _document = inject(DOCUMENT);
    private platformService = inject(PlatformService);
    block = input.required<ILinkBlock>();
    classes = computed(() => {
        const block = this.block();
        return [
            'break-words',
            ...this._tailwind.getTypographyClasses(block),
            ...this._tailwind.getSpacingClasses(block)
        ];
    });

    sanitizedLink = computed(() => {
        return SbLinkHelper.sanitizeLink(this.block().link, this._sanitizer);
    });

    ngOnInit() {
        this._route.fragment.subscribe(fragment => {
            if (fragment) {
                this.scrollTo(fragment);
            }
        });
    }

    scrollTo(fragment?: string): void {
        if ((this.platformService.isBrowser) && fragment) {
            const element = this._document.getElementById(fragment);
            if (element && window) {
                const rect = element.getBoundingClientRect();
                const offset = 100;
                const top = rect.top + window.scrollY - offset;

                window.scrollTo({
                    top: top,
                    behavior: 'smooth'
                });
            }
        }
    }

}
